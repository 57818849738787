import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Button, Checkbox, Form, Input, Select } from 'antd';
const { TextArea } = Input;
const { Option } = Select;

const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class EditProducts extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            snackbaropen: false,
            alertMessage: '',
            alertMessageType: '',
            tableLoading: true,
            btnLoading: false,
            apiUrl: process.env.REACT_APP_API_URL,
            productID: 0,
            images: [],
            inputFieldDatas: {},
            productDetails: {},
        }
        const userDetails = JSON.parse(localStorage.getItem('user'));
        

    }

    async componentDidMount() {
        this.setState({ tableLoading: false })

        const windowUrl = window.location.href;
        let url = new URL(windowUrl);
        let url22 = new URL(url.href.replace('/#', ''));
        let productIDD = new URL(url22.href).searchParams.get("id");
        this.setState({productID: productIDD});
        console.log('ddd', productIDD)

        this.getProductDetails(productIDD);
        
    }


getProductDetails = async(productID) => {
    this.setState({tableLoading: true})
    const requestType = 'productDetails';
    const productId = productID;
    fetch(`${this.state.apiUrl}/products/listProducts.php`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            requestType, productId
        })
    })
        .then(res => res.json())
        .then(json => {
            this.setState({tableLoading: false})
            if (json.status === 200) {
                this.setState({
                    productDetails: json.data[0],
                    isLoaderOpen: false
                });
                let productDetailss = json.data[0];
                this.formRef.current.setFieldsValue({
                    productName: productDetailss.product_name,
                    deliveryFreeIn: productDetailss.delivery_free_in,
                    size: productDetailss.size,
                    productCost: productDetailss.product_cost,
                    description: productDetailss.description,
                    productType: productDetailss.product_type

                });
                let objj = {};
                let name = {};
                let value = {};
                name = 'productName';
                value = productDetailss.product_name;
                objj['target']= {name, value};
                //objj['target']= value;
                console.log('json.data[0]', json.data[0])
                this.handleInputValue(objj)


                let uploadedImageList = this.state.productDetails.images_uploaded;
                let imageArray = [];
                uploadedImageList.map((item, idx)=>{
                    let imageObj = {};
                    imageObj['dataURL'] = item.images;
                    imageArray.push(imageObj);
                })
                this.setState({images: imageArray});
            } else {
                this.setState({
                    productDetails: {},
                    isLoaderOpen: false
                });
            }
        })
}



    handleInputValue = (e) => {        
            let val = '';
            var idaa = e.target.name;
            let fieldName = '';
            //const val = e.target.checked;
            let validateField = idaa.split('_');
            if(validateField.length === 1){
                val = e.target.value;
                fieldName = e.target.name;
            }else{
                val = e.target.checked ? 1 : 0;
                let validateField_2 = validateField.slice(0,-1);
                fieldName = validateField_2.join('_');
            }

            var obj = {...this.state.inputFieldDatas};
            obj[fieldName] = val;
            this.setState({ inputFieldDatas: obj })
            
        console.log('obj',obj)        
    }


    _handleLogout = () => {
        this.Auth.logout()
        this.props.history.replace('/login');
    }



    render() {
        const {productDetails} = this.state;

        const IOSSwitch = withStyles((theme) => ({
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: theme.spacing(1),
            },
            switchBase: {
                padding: 1,
                '&$checked': {
                    transform: 'translateX(16px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        backgroundColor: '#52d869',
                        opacity: 1,
                        border: 'none',
                    },
                },
                '&$focusVisible $thumb': {
                    color: '#52d869',
                    border: '6px solid #fff',
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }))(({ classes, ...props }) => {
            return (
                <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    {...props}
                />
            );
        });


        const onFinish = (values) => {
            values.productId = this.state. productID;
            console.log('Success:', values);
            console.log('val', this.state.images)
            this.setState({btnLoading:true});
            const userDetails = JSON.parse(localStorage.getItem('user'));
            const userName = userDetails.first_name+''+userDetails.last_name
            const newProductInputValues = values;
            const images = this.state.images;
            const requestType = 'editProduct';
            fetch(`${this.state.apiUrl}/products/editProduct.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userName,
                    newProductInputValues,
                    images,
                    requestType
                })
            })
            .then(res => {
                // console.log(res);
                // console.log(res.status);
                if(res.status == '200'){
                    this.setState({
                        btnLoading:false
                    });
                    window.location.href = "/#/products";
                }
            })
        };
        
          

        const onChange = (imageList,addUpdateIndex) => {
            // data for submit
            console.log(imageList, addUpdateIndex);
            this.setState({images: imageList});
          };
          const maxNumber = 69;

        return (
            <React.Fragment>
                <div id="leadsPage">
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.snackbaropen}
                        onClose={this.handleClose}
                        autoHideDuration={4000}
                        key={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={this.handleClose} severity={this.state.alertMessageType}>
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>
                    <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                        <Link color="inherit" href="/" >
                            Home
                        </Link>
                        <Typography color="textPrimary" >
                            Edit Products
                        </Typography>
                    </Breadcrumbs>
                    <div className="leadContent">
                        <Grid container spacing={3} className="headerBlock">
                            <Grid item xs={6}>
                                <a href="/#/products"><div className="headerText" style={{display:'inline-flex', color: 'firebrick'}}> <ArrowCircleLeftIcon style={{color: 'firebrick'}} /> Back to list of Products</div></a>
                            </Grid>
                            <Grid item xs={6} className="addLeadBtn" >

                            </Grid>
                        </Grid>

                        <div className="leadTableViewSec">
                        <Form
                            ref={this.formRef}
                            name="basic"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Product Details</div>

                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Form.Item
                                            label="Product Name"
                                            name="productName"
                                            rules={[
                                                {
                                                required: true,
                                                message: 'Please input your Product Name!',
                                                },
                                            ]}
                                           // value={this.state.productDetails.product_name}
                                           defaultValue={productDetails.product_name}
                                        >
                                            <Input  />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Form.Item
                                            label="Delivery Free In"
                                            name="deliveryFreeIn"
                                            rules={[
                                                {
                                                required: true,
                                                message: 'Please input your Delivery Free In!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Form.Item
                                            label="Size"
                                            name="size"
                                            rules={[
                                                {
                                                required: true,
                                                message: 'Please input your size!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Form.Item
                                            label="Product Cost"
                                            name="productCost"
                                            rules={[
                                                {
                                                required: true,
                                                message: 'Please input your Product Cost!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                            </Box>
                            <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Product Description</div>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                <   Form.Item
                                        label="Description"
                                        name="description"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Please input your Description!',
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} value={this.state.productDetails.description} />
                                    </Form.Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Form.Item
                                        label="Product Type"
                                        name="productType"
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Please input your Product Type!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            // style={{
                                            // width: 130,
                                            // }}
                                        >
                                            <Option key="" value="">None</Option>
                                            <Option key="Bounce House" value="Bounce House">Bounce House</Option>
                                            <Option key="Other" value="Other">Other</Option>
                                        </Select>
                                    </Form.Item>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <div className="" style={{marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Product Images</div>
                                <Grid item xs={12}>
                                    <div id="newCarImageUploads" style={{marginTop: '3%'}}>
                                        <ImageUploading
                                            multiple
                                            value={this.state.images}
                                            onChange={onChange}
                                            maxNumber={maxNumber}
                                        >
                                            {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps
                                            }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <div id="listOfImagesAdded" {...dragProps}>
                                                            {imageList.map((image, index) => (
                                                                <div key={index} className="image-item">
                                                                    <img src={image.dataURL} alt="" width="100" />
                                                                    <div className="image-item__btn-wrapper">
                                                                    <button className="replaceBtn" onClick={() => onImageUpdate(index)}>Update</button>
                                                                    <button className="removeBtn" onClick={() => onImageRemove(index)}>Remove</button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            Drag and Drop Images
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6} id="uploadImgCarBtns">
                                                        <span
                                                            style={isDragging ? { color: "red" } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                            className="uploadImgBtn"
                                                        >
                                                            Click to Upload Images
                                                        </span>
                                                        <span className="removeImgBtn" onClick={onImageRemoveAll}>Remove all images</span>
                                                    </Grid>
                                                </Grid>
                                                
                                                
                                                
                                            </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                    </Grid>
                            </Grid>
                            
                            <div style={{textAlign:'center', padding:'2px 0px'}}>
                               
                                <Form.Item>
                                    <a href="/#/cars" style={{textDecoration:'unset'}}><Button style={{lineHeight: '0px'}} className="goBackNewCarBtn" >Go Back</Button></a>
                                    <Button style={{lineHeight: '0px'}} className="createNewCarBtn" htmlType="submit">{this.state.btnLoading && <CircularProgress disableShrink={true} size={20}/>} Update</Button>
                                </Form.Item>
                            </div>
                        </Form>
                        
                        </div>
                    </div>



                </div>

            </React.Fragment>
        );
    }

}

export default EditProducts;