import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ImageUploading, { ImageListType } from "react-images-uploading";



const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class AddProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbaropen: false,
            alertMessage: '',
            alertMessageType: '',
            tableLoading: true,
            btnLoading: false,
            apiUrl: process.env.REACT_APP_API_URL,

            images: [],
            inputFieldDatas: {}

        }
        const userDetails = JSON.parse(localStorage.getItem('user'));
    }

    async componentDidMount() {
        this.setState({ tableLoading: false })
    }



    handleInputValue = (e) => {        
            let val = '';
            var idaa = e.target.name;
            let fieldName = '';
            //const val = e.target.checked;
            let validateField = idaa.split('_');
            if(validateField.length === 1){
                val = e.target.value;
                fieldName = e.target.name;
            }else{
                val = e.target.checked ? 1 : 0;
                let validateField_2 = validateField.slice(0,-1);
                fieldName = validateField_2.join('_');
            }

            var obj = {...this.state.inputFieldDatas};
            obj[fieldName] = val;
            this.setState({ inputFieldDatas: obj })
            
        console.log('obj',obj)        
    }


    _handleLogout = () => {
        this.Auth.logout()
        this.props.history.replace('/login');
    }



    createNewProduct = event => {
        event.preventDefault();
        this.setState({btnLoading:true});
        const userDetails = JSON.parse(localStorage.getItem('user'));
        const userName = userDetails.first_name+''+userDetails.last_name
        const newProductInputValues = this.state.inputFieldDatas;
        const images = this.state.images;
        const requestType = 'addNewProduct';
        fetch(`${this.state.apiUrl}/products/addProducts.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userName,
                newProductInputValues,
                images,
                requestType
            })
        })
        .then(res => {
            console.log(res);
            console.log(res.status);
            if(res.status == '200'){
                this.setState({
                    btnLoading:false
                });
                window.location.href = "/#/products";
            }
        })
    };




    render() {

        const IOSSwitch = withStyles((theme) => ({
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: theme.spacing(1),
            },
            switchBase: {
                padding: 1,
                '&$checked': {
                    transform: 'translateX(16px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        backgroundColor: '#52d869',
                        opacity: 1,
                        border: 'none',
                    },
                },
                '&$focusVisible $thumb': {
                    color: '#52d869',
                    border: '6px solid #fff',
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 26 / 2,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        }))(({ classes, ...props }) => {
            return (
                <Switch
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    {...props}
                />
            );
        });



        const onChange = (imageList,addUpdateIndex) => {
            // data for submit
            console.log(imageList, addUpdateIndex);
            this.setState({images: imageList});
          };
          const maxNumber = 69;

        return (
            <React.Fragment>
                <div id="leadsPage">
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.snackbaropen}
                        onClose={this.handleClose}
                        autoHideDuration={4000}
                        key={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={this.handleClose} severity={this.state.alertMessageType}>
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>
                    <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                        <Link color="inherit" href="/" >
                            Home
                        </Link>
                        <Typography color="textPrimary" >
                            Add Products
                        </Typography>
                    </Breadcrumbs>
                    <div className="leadContent">
                        <Grid container spacing={3} className="headerBlock">
                            <Grid item xs={6}>
                                <a href="/#/products"><div className="headerText" style={{display:'inline-flex', color: 'firebrick'}}> <ArrowCircleLeftIcon style={{color: 'firebrick'}} /> Back to list of Products</div></a>
                            </Grid>
                            <Grid item xs={6} className="addLeadBtn" >

                            </Grid>
                        </Grid>

                        <div className="leadTableViewSec">
                        <form 
                            autoComplete="off" 
                            onSubmit={this.createNewProduct.bind(this)}
                        >
                            <div id="addNewCarPage" style={{padding:'12px'}}>
                            <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Product Details</div>

                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Product Name"
                                                name="productName"
                                                type="text"
                                                fullWidth="true"
                                                label="Product Name"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Delivery Free In"
                                                name="deliveryFreeIn"
                                                type="text"
                                                fullWidth="true"
                                                label="Delivery Free In"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Size"
                                                name="size"
                                                type="text"
                                                fullWidth="true"
                                                label="Size"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                className="material_input"
                                                placeholder="Product Cost"
                                                name="productCost"
                                                type="text"
                                                fullWidth="true"
                                                label="Product Cost"
                                                variant="outlined"
                                                onChange={this.handleInputValue}
                                                required
                                            />
                                        </Grid>
                                        
                                    </Grid>
                                </Box>

                            <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Product Description</div>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        fullWidth="true"
                                        variant="outlined"
                                        name="description"
                                        onChange={this.handleInputValue}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-select-currency-native"
                                        name='productType'
                                        onChange={this.handleInputValue}
                                        select
                                        label="Product Type"
                                        variant="outlined"
                                        //defaultValue={row.lead_type}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        fullWidth={true} 
                                        required
                                    >
                                        <option value=""></option>
                                        <option value="Bounce House">Bounce House</option>
                                        <option value="Other">Other</option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <FormControlLabel
                                        size="small"
                                        label="isActive"
                                        control={<IOSSwitch 
                                            defaultChecked={this.state.inputFieldDatas.audio_input}  
                                            onChange={this.handleInputValue}
                                            name="audio_input_switch"
                                        />}
                                    /> */}
                                </Grid>
                               
                            </Grid>

                          

                                <div className="" style={{marginBottom:'2%',marginTop:'2%', color:'firebrick', fontSize: '20px', fontWeight: 'bold'}}>Product Images</div>


                                <div id="newCarImageUploads" style={{marginTop: '3%'}}>
                                    <ImageUploading
                                        multiple
                                        value={this.state.images}
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                    >
                                        {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                        }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <div id="listOfImagesAdded" {...dragProps}>
                                                        {imageList.map((image, index) => (
                                                            <div key={index} className="image-item">
                                                                <img src={image.dataURL} alt="" width="100" />
                                                                <div className="image-item__btn-wrapper">
                                                                <button className="replaceBtn" onClick={() => onImageUpdate(index)}>Update</button>
                                                                <button className="removeBtn" onClick={() => onImageRemove(index)}>Remove</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        Drag and Drop Images
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} id="uploadImgCarBtns">
                                                    <span
                                                        style={isDragging ? { color: "red" } : undefined}
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                        className="uploadImgBtn"
                                                    >
                                                        Click to Upload Images
                                                    </span>
                                                    <span className="removeImgBtn" onClick={onImageRemoveAll}>Remove all images</span>
                                                </Grid>
                                            </Grid>
                                            
                                            
                                            
                                        </div>
                                        )}
                                    </ImageUploading>
                                </div>


                                <div style={{textAlign:'center'}}>
                                    <a href="/#/cars" style={{textDecoration:'unset'}}><Button className="goBackNewCarBtn" >Go Back</Button></a>
                                    <Button className="createNewCarBtn" type="submit">{this.state.btnLoading && <CircularProgress disableShrink={true} size={20}/>} Create</Button>
                                </div>




                            </div>
                        </form>
                        </div>
                    </div>



                </div>

            </React.Fragment>
        );
    }

}

export default AddProducts;