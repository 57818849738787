import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MuiAlert from '@material-ui/lab/Alert';
import DataTable from 'react-data-table-component';


const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            snackbaropen: false,
            alertMessage: '',
            alertMessageType: '',
            tableLoading: true,
            btnLoading: false,
            apiUrl: process.env.REACT_APP_API_URL

        }
        const userDetails = JSON.parse(localStorage.getItem('user'));
        
    }

    async componentDidMount() {
        this.setState({ tableLoading: false })
        console.log('dddd', this.props.data)
    }

    _handleLogout = () => {
        this.Auth.logout()
        this.props.history.replace('/login');
    }






    render() {

        const columns = [
            // {
            //     name: 'Id',
            //     selector: 'id',
            //     sortable: true,
            // },
            {
                name: 'Product Name',
                //selector: 'appt_date',
                sortable: true,
                cell: row => 
                <span>{row.product_name}</span>,
            },
            {
                name: 'Delivery Free in',
                selector: 'delivery_free_in',
                sortable: true,
            },
            {
                name: 'Size',
                selector: 'size',
                sortable: true,
            },
            {
                name: 'Product Cost',
                selector: 'product_cost',
                sortable: true,
            },
        ];

        return (
            <React.Fragment>
                <div>
                    <div>
                        <h2>List of products ordered</h2>
                    </div>
                    <DataTable
                        //title="Arnold Movies"
                        columns={columns}
                        data={this.props.data.products}
                        sortable = {true}
                        responsive = {true}
                        pagination = {true}
                        paginationServer = {false}
                        noHeader={true}
                        progressPending={this.state.tableLoading}
                        progressComponent={<span>loading...</span>}
                    />


                </div>
            </React.Fragment>
        );
    }

}

export default Orders;