import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
//import AuthHelperMethods from '../../components/AuthHelperMethods';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import EditIcon from '@mui/icons-material/Edit';

const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Cars extends Component {
    constructor(props) {
		super(props);
		this.state= {
            productList:[],
            snackbaropen: false,
            alertMessage: '',
            alertMessageType: '',
            tableLoading: true,
            btnLoading: false,
            apiUrl: process.env.REACT_APP_API_URL
            
		}
        const userDetails = JSON.parse(localStorage.getItem('user'));
        this.listProducts();
	}
	
async componentDidMount(){

}

listProducts = async() => {
    this.setState({tableLoading: true})
    const requestType = 'listOfProducts';
    fetch(`${this.state.apiUrl}/products/listProducts.php`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            requestType
        })
    })
        .then(res => res.json())
        .then(json => {
            this.setState({tableLoading: false})
            if (json.status === 200) {
                this.setState({
                    productList: json.data,
                    isLoaderOpen: false
                });
            } else {
                this.setState({
                    productList: [],
                    isLoaderOpen: false
                });
            }
        })
}





_handleLogout = () => {
    this.Auth.logout()
    this.props.history.replace('/login');
}

              




render() {

    const IOSSwitch = withStyles((theme) => ({
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: theme.spacing(1),
        },
        switchBase: {
          padding: 1,
          '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
              backgroundColor: '#52d869',
              opacity: 1,
              border: 'none',
            },
          },
          '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 26 / 2,
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[50],
          opacity: 1,
          transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
        );
        });

        

    const columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Product Name',
            //selector: 'appt_date',
            sortable: true,
            cell: row => 
            <span>{row.product_name}</span>,
        },
        {
            name: 'Delivery Free In',
            selector: 'delivery_free_in',
            sortable: true,
        },
        {
            name: 'Size',
            selector: 'size',
            sortable: true,
        },
        {
            name: 'Product Cost',
            selector: 'product_cost',
            sortable: true,
        },
        {
            name: 'Is Active',
            selector: 'active_flag',
            sortable: true,

            cell: row => 
            <div>
                {<FormControlLabel
                size="small"
                disabled
                control={<IOSSwitch 
                    //onChange={this.handleIsConfirmStatus} 
                    name={row.id} 
                    disabled={true}
                    defaultChecked={row.active_flag === '1' ? true : false}  
                    />}
                />}
            </div>,
        },
        {
            name: 'Action',
            cell: row => 
            <div>
                <a href={`/#/edit-products?id=${row.id}`}>
                    <EditIcon />
                </a>
            </div>,
        },
    ];
    

    
return (
    <React.Fragment>
        <div id="leadsPage">
            <Snackbar
                anchorOrigin={{  vertical: 'top', horizontal: 'right' }}
                open={this.state.snackbaropen}
                onClose={this.handleClose}
                autoHideDuration={4000}
                key={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={this.handleClose} severity={this.state.alertMessageType}>
                    {this.state.alertMessage}
                </Alert>
            </Snackbar>
            <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                <Link color="inherit" href="/" >
                    Home
                </Link>
                <Typography color="textPrimary" >
                    Products
                </Typography>
            </Breadcrumbs>
            <div className="leadContent">
                <Grid container spacing={3} className="headerBlock">
                    <Grid item xs={6}>
                        <div className="headerText">Manage Products</div>
                    </Grid>
                    <Grid item xs={6} className="addLeadBtn" >
                      
                    </Grid>
                </Grid>

                <div className="leadTableViewSec">
                    <div className="tableHeaderBlock">
                        <Grid container spacing={3} className="headerBlock">
                            <Grid item xs={6}>
                                <TextField onChange={this.tableSearchInput} className="tableSearchInput" label="Search for Products" />
                            </Grid>
                            <Grid item xs={6} className="addLeadBtn" >
                                <a href="/#/add-product">
                                    <Button variant="contained" color="primary">
                                        Add New Product
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="tableBlock">
                        <DataTable
                            //title="Arnold Movies"
                            columns={columns}
                            data={this.state.productList}
                            sortable = {true}
                            responsive = {true}
                            pagination = {true}
                            paginationServer = {false}
                            //fixedHeader = {true}
                            expandableIcon={{
                                collapsed: <VisibilityOutlinedIcon />,
                                expanded: <VisibilityOffOutlinedIcon />
                                }}
                            noHeader={true}
                            //selectableRows
                            progressPending={this.state.tableLoading}
                            progressComponent={<span>loading...</span>}
                            //selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={{ inkDisabled: true }}
                            //sortIcon={<FontIcon>arrow_downward</FontIcon>}
                            //onSelectedRowsChange={}
                        />
                    </div>
                </div>
            </div>



        </div>
                       {/* <div className="signiture">
                        <h1>Template Built & Designed by Roman Chvalbo</h1>
                    </div> */}
            </React.Fragment>
        );
    }

}

export default Cars;