import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
import AuthHelperMethods from '../../components/AuthHelperMethods';
import Donut from "./Donut";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox, Row, Col, Typography, message } from 'antd';
const { Title } = Typography;
const dotenv = require("dotenv");
dotenv.config({ path: ".env" });

class Dashboard extends Component {
    popupFormRef = React.createRef();
    bannerFormRef = React.createRef();
    Auth = new AuthHelperMethods();
    constructor(props) {
        super(props);
        this.state = {
            countsData: [],
            isLoaderOpen: true,
            vicidialPanel: false,
            random: 0,
            profileDetails: JSON.parse(localStorage.getItem('user')),
            apiUrl: process.env.REACT_APP_API_URL,
            dashboardContent: {},
            apiKey: 'da731be431a0e7d95f01f98888ac94c1'
        }
        const userDetails = JSON.parse(localStorage.getItem('user'));
    }

    async componentDidMount() {
        this.setState({ isLoaderOpen: false })
        this.listbannerAndPopup();
    }
    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */




    _handleLogout = () => {
        this.Auth.logout()
        this.props.history.replace('/login');
    }



    listbannerAndPopup = async() => {
        this.setState({tableLoading: true})
        const requestType = 'fetchDashboardContent';
        fetch(`${this.state.apiUrl}/dashboard/dashboardContent.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType
            })
        })
        .then(res => res.json())
        .then(json => {
            this.setState({tableLoading: false})
            if (json.status === 200) {
                this.setState({
                    dashboardContent: json.data[0],
                    isLoaderOpen: false
                });
                this.popupFormRef.current.setFieldsValue({
                    popup_header : json.data[0].popup_header,
                    popup_content : json.data[0].popup_content,
                });

                this.bannerFormRef.current.setFieldsValue({
                    banner_header : json.data[0].banner_header,
                    banner_content : json.data[0].banner_content
                });

            } else {
                this.setState({
                    dashboardContent: {},
                    isLoaderOpen: false
                });
            }
        })
    }




    render() {
        const { isLoaderOpen } = this.state;


        const onFinishPopupTxt = (values) => {
            this.setState({tableLoading: true})
            const header = values.popup_header;
            const content = values.popup_content;
            const columnHeaderName = 'popup_header';
            const columnContentName = 'popup_content';
            const requestType = 'updateDashboardContent';
            fetch(`${this.state.apiUrl}/dashboard/dashboardContent.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    requestType, header, content, columnHeaderName, columnContentName
                })
            })
                .then(res => res.json())
                .then(json => {
                    this.setState({tableLoading: false})
                    if (json.status === 200) {
                        message.success('Updated Successfully!');

                        this.setState({
                            isLoaderOpen: false
                        });
                    } else {
                        this.setState({
                            isLoaderOpen: false
                        });
                        message.error('Something went wrong, Please contact Administrator!');

                    }
                })
        };

        const onFinishBannerTxt = (values) => {
            this.setState({tableLoading: true})
            const header = values.banner_header;
            const content = values.banner_content;
            const columnHeaderName = 'banner_header';
            const columnContentName = 'banner_content';
            const requestType = 'updateDashboardContent';
            fetch(`${this.state.apiUrl}/dashboard/dashboardContent.php`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    requestType, header, content, columnHeaderName, columnContentName
                })
            })
            .then(res => res.json())
            .then(json => {
                this.setState({tableLoading: false})
                if (json.status === 200) {
                    message.success('Updated Successfully!');

                    this.setState({
                        isLoaderOpen: false
                    });
                } else {
                    this.setState({
                        isLoaderOpen: false
                    });
                    message.error('Something went wrong, Please contact Administrator!');

                }
            })
        };


        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div id="dashboardPage">
                    {/* asdfasdf
        <button onClick={this._handleLogout}>LOGOUT</button> */}
                    <Breadcrumbs className="headerBreadcrumb" aria-label="breadcrumb">
                        <Typography color="textPrimary" >
                            Dashboard
                        </Typography>
                    </Breadcrumbs>

                    <Row style={{ marginTop: '5%' }}>

                        <Col span={12}>
                            <Title>PopUp Content</Title>
                            <Form
                                name="basic"
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 16 }}
                                onFinish={onFinishPopupTxt}
                                autoComplete="off"
                                ref={this.popupFormRef}
                            >
                                <Form.Item
                                    label="Header"
                                    name="popup_header"
                                    rules={[{ required: true, message: 'Please input your Header!' }]}
                                >
                                    <Input  />
                                </Form.Item>

                                <Form.Item
                                    label="Content"
                                    name="popup_content"
                                    rules={[{ required: true, message: 'Please input your Content!' }]}
                                >
                                    <Input.TextArea allowClear showCount rows={4}  />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={12}>
                            <Title>Banner Content</Title>
                            <Form
                                name="basic"
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinishBannerTxt}
                                autoComplete="off"
                                ref={this.bannerFormRef}
                            >
                                <Form.Item
                                    label="Header"
                                    name="banner_header"
                                    rules={[{ required: true, message: 'Please input your Header!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Content"
                                    name="banner_content"
                                    rules={[{ required: true, message: 'Please input your Content!' }]}
                                >
                                    <Input.TextArea allowClear showCount rows={4}  />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>


                </div>
            </React.Fragment>
        );
    }

}

export default Dashboard;